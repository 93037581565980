import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import {
  cva,
  type VariantProps as CvaVariantProps,
} from 'class-variance-authority';

import { cn } from '@/lib/utils';

const bodyVariants = cva('', {
  variants: {
    variant: {
      bold: ['font-bold'],
      regular: ['font-normal'],
      medium: ['font-medium'],
    },
    size: {
      sm: [
        // Mobile
        'text-xs',
        'tracking-sm',
        'leading-xl',
        // Desktop
        'sm:text-sm',
        'sm:tracking-sm',
        'sm:leading-lg',
      ],
      xs: [
        // Mobile
        'text-xxs',
        'tracking-sm',
        'leading-lg',
        // Desktop
        'sm:text-xs',
        'sm:tracking-sm',
        'sm:leading-xl',
      ],
      xxs: [
        // Mobile
        'text-xxs',
        'tracking-sm',
        'leading-lg',
        // Desktop
        'sm:text-xxs',
        'sm:tracking-sm',
        'sm:leading-lg',
      ],
      xxxs: [
        // Mobile
        'text-xxxs',
        'tracking-md',
        'leading-md',
        // Desktop
        'sm:text-xxxs',
        'sm:tracking-md',
        'sm:leading-md',
      ],
    },
  },
  defaultVariants: {
    variant: 'regular',
    size: 'sm',
  },
});

type NativeProps = React.ComponentPropsWithoutRef<'p'>;
type VariantProps = CvaVariantProps<typeof bodyVariants>;

export interface BodyProps extends VariantProps, NativeProps {
  asChild?: boolean;
}

const Body = React.forwardRef<HTMLParagraphElement, BodyProps>(
  ({ className, variant, size, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';
    return (
      <Comp
        ref={ref}
        className={cn(bodyVariants({ variant, size }), className)}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

Body.displayName = 'Body';

export { Body, bodyVariants };
