import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const tshirtUnitRegex = /^(\d+(\.\d+)?)?(xxxs|xxs|xs|sm|md|lg|xl|xxl)$/;
const fontWeightUnitRegex = /^(\d+(\.\d+)?)?(regular|medium|bold)$/;

function isTshirtSize(value: string) {
  return tshirtUnitRegex.test(value);
}

function isFontWeight(value: string) {
  return fontWeightUnitRegex.test(value);
}

function isNumber(value: string) {
  return Boolean(value) && !Number.isNaN(Number(value));
}

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      borderWidth: ['none', 'xs', 'sm'],
    },
    classGroups: {
      'font-size': [{ text: [isTshirtSize] }],
      'font-weight': [{ font: [isFontWeight] }],
      leading: [{ leading: [isTshirtSize] }],
      tracking: [{ tracking: [isTshirtSize] }],
      shadow: [{ shadow: [isNumber] }],
    },
  },
});

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}
