import * as React from 'react';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { Loader2Icon } from 'lucide-react';

import { interactiveVariants } from '../typography';
import { type BaseProps } from '../utils';

import { cn } from '@/lib';

const buttonVariants = cva(
  [
    // Base font
    'font-medium',
    'text-pure',

    // Base layout
    'px-3',
    'flex',
    'items-center',
    'justify-center',
    'gap-2',

    // Base appearance
    'rounded-sm',
    'border-xs',

    // Focus
    'focus-visible:ring-focus-default',

    // Disabled
    'aria-[disabled=true]:pointer-events-none',
    'aria-[disabled=true]:border-disabled',
    'aria-[disabled=true]:text-onInteractive-disabled',
    'group',
  ],
  {
    variants: {
      variant: {
        accent: [
          'bg-interactive-accent-default',
          'text-onInteractive-accent',
          'hover:bg-interactive-accent-hovered',
          'active:bg-interactive-accent-pressed',
          'data-[alert=true]:bg-interactive-alert-accent-default',
          'data-[alert=true]:text-onInteractive-alert-accent',
          'data-[alert=true]:hover:bg-interactive-alert-accent-hovered',
          'data-[alert=true]:active:bg-interactive-alert-accent-pressed',
          'aria-[disabled=true]:data-[alert=true]:bg-interactive-disabled',
          'aria-[disabled=true]:data-[alert=true]:text-onInteractive-disabled',
          'aria-[disabled=true]:bg-interactive-disabled',
          'aria-[disabled=true]:text-onInteractive-disabled',
          'aria-[disabled=true]:border-none',
        ],
        subtle: [
          'bg-interactive-subtle-default',
          'border-interactive-subtle-default',
          'text-onInteractive-subtle',
          'hover:bg-interactive-subtle-hovered',
          'active:bg-interactive-subtle-pressed',
          'data-[alert=true]:bg-interactive-alert-subtle-default',
          'data-[alert=true]:text-onInteractive-alert-subtle',
          'data-[alert=true]:hover:bg-interactive-alert-subtle-hovered',
          'data-[alert=true]:active:bg-interactive-alert-subtle-pressed',
          'aria-[disabled=true]:data-[alert=true]:bg-interactive-disabled',
          'aria-[disabled=true]:data-[alert=true]:text-onInteractive-disabled',
          'aria-[disabled=true]:bg-interactive-disabled',
          'aria-[disabled=true]:text-onInteractive-disabled',
          'aria-[disabled=true]:border-none',
        ],
        outline: [
          'bg-surface-subtle-default',
          'border-subtle',
          'hover:bg-surface-subtle-hovered',
          'active:bg-surface-subtle-pressed',
          'aria-[disabled=true]:border-disabled',
        ],
        ghost: [
          'bg-others-transparency',
          'border-none',
          'no-underline',
          'hover:bg-surface-subtle-hovered',
          'active:bg-surface-subtle-pressed',
          'aria-[disabled=true]:border-none',
        ],
      },
      size: {
        xs: [interactiveVariants({ size: 'xxs', variant: 'medium' }), 'h-8'],
        sm: [interactiveVariants({ size: 'xs', variant: 'medium' }), 'h-10'],
        md: [interactiveVariants({ size: 'sm', variant: 'medium' }), 'h-12'],
        lg: [interactiveVariants({ size: 'sm', variant: 'medium' }), 'h-14'],
      },
    },
    defaultVariants: {
      variant: 'accent',
      size: 'sm',
    },
  },
);

export interface ButtonProps
  extends BaseProps<'button', typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingText?: string;
  alert?: boolean;
}

const buttonIconCN = cn([
  'size-5',
  'group-data-[size=xs]:size-4-5',
  'group-data-[size=lg]:size-6',
  '[&_svg]:size-full',
  'group-data-[variant=accent]:text-icon-onInteractive-accent',
  'group-data-[variant=subtle]:text-icon-onInteractive-subtle',
  'group-data-[variant=outline]:text-icon-accent',
  'group-data-[variant=ghost]:text-icon-accent',
  'group-data-[variant=accent]:group-data-[alert=true]:text-icon-onInteractive-alert-accent',
  'group-data-[variant=subtle]:group-data-[alert=true]:text-icon-onInteractive-alert-subtle',
  'group-aria-disabled:text-icon-disabled',
  'group-data-[alert=true]:group-aria-disabled:text-icon-disabled',
]);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      loading = false,
      loadingText = 'Aguarde',
      asChild = false,
      alert = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        data-size={size}
        data-variant={variant}
        data-alert={alert}
        aria-disabled={props.disabled}
        {...props}
      >
        {loading && (
          <span className={buttonIconCN}>
            <Loader2Icon
              style={{ color: 'inherit' }}
              className="animate-spin"
            />
          </span>
        )}
        <Slottable>{loading ? loadingText : children}</Slottable>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

const ButtonLeadIcon = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => {
  return <span className={cn(buttonIconCN, className)} {...props} ref={ref} />;
});
ButtonLeadIcon.displayName = 'ButtonLeadIcon';

const ButtonTrailingIcon = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => {
  return <span className={cn(buttonIconCN, className)} {...props} ref={ref} />;
});
ButtonTrailingIcon.displayName = 'ButtonTrailingIcon';

export { Button, ButtonLeadIcon, ButtonTrailingIcon, buttonVariants };
