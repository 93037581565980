import * as React from 'react';

import { cva } from 'class-variance-authority';

import { type BaseProps } from '../utils';

import { cn } from '@/lib/utils';

const cardVariants = cva(
  [
    // Border
    'border',
    'rounded-sm',
    'border-xs',
    'border-subtle',
    // Background
    'bg-canvas-1',
    // Animation
    'transition-shadow',
    'duration-300',
  ],
  {
    variants: {
      padding: {
        none: ['p-0'],
        sm: ['p-4'],
        lg: ['p-6'],
      },
      shadow: {
        1: ['shadow-1'],
        2: ['shadow-2'],
        3: ['shadow-3'],
        4: ['shadow-4'],
        5: ['shadow-5'],
        6: ['shadow-6'],
      },
    },
    defaultVariants: {
      padding: 'sm',
      shadow: 1,
    },
  },
);

type CardProps = BaseProps<'div', typeof cardVariants>;

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, padding, shadow, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(cardVariants({ padding, shadow }), className)}
      {...props}
    />
  ),
);
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('mb-4 flex flex-col', className)} {...props} />
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'text-2xl font-semibold leading-none tracking-tight',
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-muted-foreground text-sm', className)}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('pt-0', className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center p-6 pt-0', className)}
    {...props}
  />
));
CardFooter.displayName = 'CardFooter';

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
