import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { type BaseProps } from '../utils';

import { cn } from '@/lib/utils';

const headingVariants = cva('', {
  variants: {
    variant: {
      bold: ['font-bold'],
      // When using overline, does not need to use the size prop
      overline: [
        // Mobile
        'font-medium',
        'uppercase',
        'text-xxxs',
        'tracking-md',
        'leading-md',
        // Desktop
        'sm:text-xxxs',
        'sm:tracking-md',
        'sm:leading-md',
      ],
      medium: ['font-medium'],
    },
    size: {
      xxl: [
        // Mobile
        'text-lg',
        'leading-sm',
        'tracking-sm',
        // Desktop
        'sm:text-xxl',
        'sm:leading-xs',
        'sm:tracking-sm',
      ],
      xl: [
        // Mobile
        'text-md',
        'leading-md',
        'tracking-sm',
        // Desktop
        'sm:text-xl',
        'sm:leading-sm',
        'sm:tracking-sm',
      ],
      lg: [
        // Mobile
        'text-md',
        'leading-md',
        'tracking-sm',
        // Desktop
        'sm:text-lg',
        'sm:leading-sm',
        'sm:tracking-sm',
      ],
      md: [
        // Mobile
        'text-sm',
        'leading-md',
        'tracking-sm',
        // Desktop
        'sm:text-md',
        'sm:leading-md',
        'sm:tracking-sm',
      ],
      sm: [
        // Mobile
        'text-xs',
        'leading-sm',
        'tracking-sm',
        // Desktop
        'sm:text-sm',
        'sm:leading-md',
        'sm:tracking-sm',
      ],
      xs: [
        // Mobile
        'text-xs',
        'leading-sm',
        'tracking-sm',
        // Desktop
        'sm:text-xs',
        'sm:leading-sm',
        'sm:tracking-sm',
      ],
      xxxs: [
        // Mobile
        'text-xxxs',
        'tracking-md',
        'leading-md',
        // Desktop
        'sm:text-xxxs',
        'sm:tracking-md',
        'sm:leading-md',
      ],
    },
  },
});

export interface HeadingProps extends BaseProps<'p', typeof headingVariants> {
  asChild?: boolean;
}

const Heading = React.forwardRef<HTMLParagraphElement, HeadingProps>(
  ({ className, variant, size, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';
    return (
      <Comp
        ref={ref}
        className={cn(headingVariants({ variant, size }), className)}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

Heading.displayName = 'Heading';

export { Heading, headingVariants };
