import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { type BaseProps } from '../utils';

import { cn } from '@/lib/utils';

const interactiveVariants = cva(
  [
    // Change the color of the text based on the parent state
    // use data-active="true" to change the color of the text
    'group-data-[active=true]:text-interactive-accent-default',
  ],
  {
    variants: {
      variant: {
        bold: ['font-bold'],
        regular: ['font-normal'],
        medium: ['font-medium'],
      },
      size: {
        sm: ['text-sm', 'leading-md', 'tracking-sm'],
        xs: ['text-xs', 'leading-xl', 'tracking-sm'],
        xxs: ['text-xxs', 'leading-xs', 'tracking-sm'],
      },
    },
    defaultVariants: {
      variant: 'regular',
      size: 'sm',
    },
  },
);

export interface InteractiveProps
  extends BaseProps<'p', typeof interactiveVariants> {
  asChild?: boolean;
}

const Interactive = React.forwardRef<HTMLParagraphElement, InteractiveProps>(
  ({ className, variant, size, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'span';
    return (
      <Comp
        ref={ref}
        className={cn(interactiveVariants({ variant, size }), className)}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

Interactive.displayName = 'Interactive';

export { Interactive, interactiveVariants };
